import './index.scss'
import React, { useContext } from 'react'
import { FatherContext } from '@/FatherContext'

import { Progress, message } from 'antd';

import Knife from '@/assets/images/knife_black.png'
import Cart from '@/assets/images/Cart_white.png'
import Shop from '@/assets/images/shop_red.png'
import Arrow from '@/assets/images/arrow.svg'
import rightBrown from '@/assets/images/right_brown.png'

import { cut_record } from '@/request/api'

export default function Cutting(props) {

    let precent = useContext(FatherContext).precent
    let cutState = useContext(FatherContext).cutState
    let hour = useContext(FatherContext).hour
    let minute = useContext(FatherContext).minute
    let second = useContext(FatherContext).second
    let priceTotal = useContext(FatherContext).priceTotal
    let priceCut = useContext(FatherContext).priceCut
    let flag = useContext(FatherContext).flag
    let login = useContext(FatherContext).login
    let tel = useContext(FatherContext).tel
    let buyNowUrl = useContext(FatherContext).buyNow
    let productUrl = useContext(FatherContext).productUrl


    const cutPrice = () => {
        if (login === false) {
            props.setLoginWindow('block')
        } else {
            tel.code = sessionStorage.getItem("code")
            let params = tel
            console.log(params);
            // 调用砍价接口
            cut_record(params).then((res) => {
                if (res.data.code === 0) {
                    props.setFlag(false)
                    props.setDiscountWindow('block')
                    props.setUserPrice(res.data.data.bargain_price)
                } else {
                    message.error("Bargaining failed")
                }
            })
        }
    }

    const buyNow = () => {
        console.log(buyNowUrl);
        window.location.href = buyNowUrl
    }

    const goProduct = () => {
        window.location.href = productUrl
    }

    return (
        <>
            {
                cutState === 'cutting' ? (
                    <div className='cutPrice'>
                        <div className='cut-price'>
                            -${priceCut}
                        </div>
                        {/* 进度条 */}
                        <div className='cut-progress'>
                            <Progress
                                strokeWidth='21px'
                                percent={precent}
                                strokeColor='#FF7149'
                                trailColor='#F5E6CA'
                                showInfo={false}
                            />
                        </div>
                        <div className='cut-maxprice'>
                            Up to ${priceTotal} off
                        </div>
                        <div className='cut-buttom'>
                            {
                                flag === true ? (
                                    <div onClick={cutPrice} className='cut-btn'>
                                        <div className='cut-icon'>
                                            <img src={Knife} alt='' />
                                        </div>
                                        <div className='cut-text'>
                                            Cut the price
                                        </div>
                                    </div>
                                ) : (
                                    <div className='cut-btnSuccess'>
                                        <div className='cut-iconSuccess'>
                                            <img src={rightBrown} alt='' />
                                        </div>
                                        <div className='cut-textSuccess'>
                                            Bargained
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div className='cut-time'>
                            <div className='cut-time-text'>
                                Count Down
                            </div>
                            <div className='cut-time-time'>
                                <span>{hour}</span>
                                <i>:</i>
                                <span>{minute}</span>
                                <i>:</i>
                                <span>{second}</span>
                            </div>
                        </div>
                    </div>
                ) : cutState === 'success' ? (
                    <div className='cutPrice'>
                        <div className='cut-price'>
                            -${priceCut}
                        </div>
                        {/* 进度条 */}
                        <div className='cut-progress'>
                            <Progress
                                strokeWidth='21px'
                                percent={precent}
                                strokeColor='#FF7149'
                                trailColor='#F5E6CA'
                                showInfo={false}
                            />
                        </div>
                        <div className='cut-maxprice'>
                            bargain at most ${priceTotal}
                        </div>
                        <div className='cut-buttom'>
                            <div onClick={buyNow} className='cut-btn-success'>
                                <div className='cut-icon-success'>
                                    <img src={Cart} alt='' />
                                </div>
                                <div className='cut-text'>
                                    Buy now！
                                </div>
                            </div>
                        </div>
                        <div className='cut-time-success'>
                            Congratulations！
                        </div>
                    </div>
                ) : (
                    <div className='cutPrice'>
                        <div className='cut-price'>
                            -${priceCut}
                        </div>
                        {/* 进度条 */}
                        <div className='cut-progress'>
                            <Progress
                                strokeWidth='21px'
                                percent={precent}
                                strokeColor='#FF7149'
                                trailColor='#F5E6CA'
                                showInfo={false}
                            />
                        </div>
                        <div className='cut-maxprice'>
                            bargain at most ${priceTotal}
                        </div>
                        <div className='cut-buttom'>
                            <div className='cut-btn-fail'>
                                <p>Bargaining failed!</p>
                                <p>Please restart the bargain!</p>
                            </div>
                        </div>
                        <div onClick={goProduct} className='cut-time-fail'>
                            <div className='cut-time-text-fail'>
                                <img src={Shop} alt='' />
                            </div>
                            <div className='cut-time-time-fail'>
                                Return to product page
                                <img src={Arrow} alt='' />
                            </div>
                        </div>
                    </div>
                )
            }

        </>
    )
}