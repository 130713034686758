import './index.scss'
import React, { useContext } from 'react'
import {FatherContext} from '@/FatherContext'

import Close from '@/assets/images/popup_close.png'
import Coupon from '@/assets/images/coupon_bg.png'
import Shop from '@/assets/images/shop_red.png'
import Arrow from '@/assets/images/arrow.svg'

export default function Discount(props){

    let shopName = useContext(FatherContext).shopName
    let discountWindow = useContext(FatherContext).discountWindow
    let productUrl = useContext(FatherContext).productUrl
    let userPrice = useContext(FatherContext).userPrice

    const hideDiscount=()=>{
        props.setDiscountWindow('none')
    }

    const discountBack =()=>{
        window.location.href=productUrl
    }

    return(
        <div className="discount-mask" style={{ display: discountWindow }}>
                <div className="discount-content">
                    <div className='discount-close'>
                        <span onClick={hideDiscount}>
                            <img src={Close} alt='' />
                        </span>
                    </div>
                    <div className='discount-bg'>
                        <img src={Coupon} alt='' />
                    </div>
                    <div className='discount-text'>
                        <p>
                            Successfully bargain ${userPrice/100} for your friends!
                        </p>
                        <p>
                            You get a 10% coupon, which will be sent to your mobile phone by SMS！
                        </p>
                    </div>
                    <div className='discount-bottom'>
                        <p onClick={discountBack}>
                            <img className='shop-icon' src={Shop} alt='' />
                            <span className='discont-shopName'>
                                {shopName}
                            </span>
                            <span className='discount-arrow'>
                                <img src={Arrow} alt='' />
                            </span>
                        </p>
                    </div>
                </div>
            </div>
    )
}