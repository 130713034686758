import './cut.scss'
import React, { useState, useEffect } from 'react'
import { Input } from 'antd';
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone';

import { query_activities } from '@/request/api'

import searchImg from '@/assets/images/home_search@2x.png'

export default function Cut() {
  var zone = -(new Date().getTimezoneOffset() * 60 * 1000)

  let [tablebar, setClickbar] = useState([
    { name: 'All', activeColor: true, key: '' },
    { name: 'In progress', activeColor: false, key: 'in_progress' },
    { name: 'Not started', activeColor: false, key: 'not_started' },
    { name: 'Ended', activeColor: false, key: 'ended' }
  ]);

  let [title, setTitle] = useState('')
  let [status, setStatus] = useState('')
  let [queryState, setQueryState] = useState(true)

  let [tablelist, setTablelist] = useState([])

  // 通过计算时间判断活动状态
  const timeState = (start_time, end_time) => {
    let count = ''

    // 将日期转化为时间戳
    let startTime = moment(start_time).valueOf() + zone * 1
    let endTime = moment(end_time).valueOf() + zone * 1
    // 获取当前时间戳
    let nowTime = moment().valueOf()
    if (startTime > nowTime && nowTime < endTime) {
      count = "Not started"
    }
    if (startTime < nowTime && nowTime < endTime) {
      count = "In progress"
    }
    if (startTime < nowTime && nowTime >= endTime) {
      count = "Ended"
      if (startTime === endTime) {
        count = "Not started"
      }
    }
    return count
  }

  // 查询活动
  const queryActivities = () => {
    let params = {
      page: 1,
      title: title,
      status: status
    }
    query_activities(params).then((res) => {
      setTablelist(() => {
        res.data.data.map((item) => {
          item.count = timeState(item.start_time, item.end_time)
          if (item.fixed_value === 0) {
            item.fixed_value = item.fixed_percentage / 100 * item.price
          }
          let startTime = moment(item.start_time).valueOf() + zone * 1
          let endTime = moment(item.end_time).valueOf() + zone * 1
          let createTime = moment(item.create_time).valueOf() + zone * 1

          item.start_time = moment(startTime).format('YYYY-MM-DD HH:mm:ss')
          item.end_time = moment(endTime).format('YYYY-MM-DD HH:mm:ss')
          item.create_time = moment(createTime).format('YYYY-MM-DD HH:mm:ss')
        })
        return res.data.data
      })
    })
  }

  useEffect(() => {
    queryActivities()
  }, [queryState]);

  // 搜索框
  const onSearch = e => {
    if (e.keyCode === 13) {
      setTitle(e.target.value)
      setQueryState(!queryState)
    }
  }

  const history = useHistory();

  const handleClickCreate = () => {
    history.push('/Cut/Create')
  }

  const pushDetail = (e, data) => {
    history.push({ pathname: '/Cut/Details' })
    sessionStorage.setItem("details", JSON.stringify(data.item));
  }

  return (
    <div>
      <div className="cut-topbar">
        <p className="cut-clickbar">Campaign</p>
        <div
          className="create-cut"
          onClick={handleClickCreate}
        >
          Create campaign
        </div>
      </div>
      <div className="search-input">
        <img src={searchImg} alt="" />
        <Input className='input-main' placeholder="Search product ..." onKeyDown={e => onSearch(e)} />
      </div>
      <div className="cut-tablebar">
        {
          tablebar.map((item, index) => {
            return (
              <span
                className={item.activeColor ? 'point' : 'nopoint'}
                onClick={e => {
                  let navList = [...tablebar]
                  navList.map((item, idx) => {
                    if (idx === index) {
                      navList[idx].activeColor = true
                      setStatus(item.key)
                      setQueryState(!queryState)
                    } else {
                      navList[idx].activeColor = false
                    }
                  })
                  setClickbar(() => {
                    return tablebar = navList
                  })
                }}
                key={index}
              >{item.name}</span>
            )
          })
        }
      </div>
      <div className="shopping">
        {
          tablelist.map((item, index) => {
            return (
              <div key={index}>
                <div className='border-padding'></div>
                <div onClick={e => pushDetail(e, { item })} className="cut-shopping">
                  <div className="cut-img">
                    <img src={item.image} alt="" />
                  </div>
                  <div className="cut-name">
                    <p>{item.create_time}</p>
                    <p>Item：{item.title}</p>
                  </div>
                  <div className="cut-set">
                    <p>Bargaining ceiling：${item.fixed_value / 100}</p>
                    <p>Each bargaining interval：${item.bargain_min / 100} ~ ${item.bargain_max / 100}</p>
                    <p>Bargaining limit duration：{item.time_limit}</p>
                    <p>Activity time：{item.start_time} ~ {item.end_time}</p>
                  </div>
                  <div className="cut-state">
                    {
                      item.count === "In progress" ? (<p style={{ background: '#A3FBF4' }}>{item.count}</p>) : item.count === "Not started" ? (<p style={{ background: '#FEF0D8' }}>{item.count}</p>) : (<p style={{ background: '#F4F4F4' }}>{item.count}</p>)
                    }
                    {/* <div className="cut-icon">
                      <UnorderedListOutlined style={{ fontSize: '26px' }} />
                    </div> */}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
