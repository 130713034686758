//在index.js中引入axios
import axios from 'axios';
import QS from 'qs'
import { message } from 'antd'

const service = axios.create({
  // 当创建实例的时候默认配置
  xsrfCookieName: 'xsrf-token',
});

// 请求拦截器
service.interceptors.request.use(config => {
  let token = window.localStorage.getItem("token")
  config.headers = {
    'token': token
  }
  // 如果是get请求，序列化参数
  if (config.method === 'get') {
    config.data = QS.stringify(config.data)
  }
  return config
}, error => {
  return error;
})

// 响应拦截器
service.interceptors.response.use(response => {
  //根据返回不同的状态码做不同的事情
  if (response.status) {

    switch (response.data.code) {
      case 0:
        return response;
      case 302:
        // 重定向到登录页面
        window.localStorage.removeItem("token")
        window.location.href = response.data.location;
        break;
      case 400:
        break;
      case 404:
        //未登录处理方法
        break;
      case 403:
        //token过期处理方法
        break;
      case 900:
      default:
        message.error(response.data.msg)
    }
  } else {
    return response;
  }
})

export default service
