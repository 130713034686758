import './index.scss'
import React, { useState } from 'react'
import { message } from 'antd'

import Linkimg from '@/assets/images/link.png'
import Ins from '@/assets/images/ins.png'
import Twitter from '@/assets/images/twitter.png'
import Facebook from '@/assets/images/facebook.png'
import Facebookmsg from '@/assets/images/facebookmsg.png'
import Whatsapp from '@/assets/images/whatsapp.png'


export default function Shareing() {

    let [labels, setLabels] = useState('I found a great shop! Come and help me cut the price! You can also get a discount!——Price Ninja')

    let urlCode = sessionStorage.getItem("code")

    let shareLink = `https://thepriceninja.com/share/?code=${urlCode}`

    let twitterLink = `https://twitter.com/intent/tweet?
    url=${shareLink} & text=PriceNinja`

    let facebookLink = `https://www.facebook.com/`
    let whatsappLink = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareLink)}&url=${encodeURIComponent(shareLink)}`

    // 点击复制链接
    const copyLink = () => {
        let url = shareLink;
        let copyInput = document.createElement('input');
        document.body.appendChild(copyInput);
        copyInput.setAttribute('value', url);
        copyInput.select();
        document.execCommand("Copy");
        message.success("Link copied!")
        copyInput.remove();
    }

    // 点击其他复制链接
    const copyLinkMessage = () => {
        let url = shareLink;
        let copyInput = document.createElement('input');
        document.body.appendChild(copyInput);
        copyInput.setAttribute('value', url);
        copyInput.select();
        document.execCommand("Copy");
        message.success("Link copied!")
        copyInput.remove();
    }

    // 文本框内容改变
    const onChange = (e) => {
        setLabels(e.target.value)
    }

    const sendCopy = () => {
        var u = navigator.userAgent;
        //判断是否为android终端
        // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
        //判断是否为ios终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        //获取要分享的端口号
        var tel = '';
        //这里填写要分享的内容
        var des = labels + '.' + shareLink;
        //ios终端
        if (isiOS) {
            document.getElementsByTagName('a')[0].href = "sms:" + tel + "&body=" + des;
            //安卓终端
        } else {
            document.getElementsByTagName('a')[0].href = "sms:" + tel + "?body=" + des;
        }
    }

    return (
        <div className='shareing-main'>
            <div className='share-text'>
                <p>Invite your friends to help you cut the price! </p>
                <p>Everyone gets a shot. </p>
                <p>They also get a 10% discount!</p>
            </div>
            <div className='share-textarea'>
                <textarea onChange={onChange} value={labels} >
                    
                </textarea>
            </div>
            <div className='share-bottom'>
                <div className='share-btn'>
                    <a href='' onClick={sendCopy}>
                        Send via SMS
                    </a>
                </div>
            </div>
            <div className='share-link'>
                <p onClick={copyLink}><img className='share-img' src={Linkimg} alt='' /></p>
                <p onClick={copyLinkMessage}>
                    <a href='https://www.instagram.com/'>
                        <img className='share-img' src={Ins} alt='' />
                    </a>
                </p>
                <p onClick={copyLinkMessage}>
                    <a href={twitterLink} target="_blank" id="J-share-twitter">
                        <img className='share-img' src={Twitter} alt='' />
                    </a>
                </p>
                <p onClick={copyLinkMessage}>
                    <a href={facebookLink} id="J-share-facebook">
                        <img className='share-img' src={Facebook} alt='' />
                    </a>
                </p>
                <p onClick={copyLinkMessage}>
                    <a href='https://www.facebook.com/'>
                        <img className='share-img' src={Facebookmsg} alt='' />
                    </a>
                </p>
                <p onClick={copyLinkMessage}>
                    <a href={whatsappLink} target="_blank" id="J-share-whatsapp">
                        <img className='share-img' src={Whatsapp} alt='' />
                    </a>
                </p>
            </div>
        </div>
    )
}