import './index.scss'
import React, { useState, useEffect } from 'react';
import * as echarts from 'echarts';
import { bargain_statistics } from "@/request/api"
import { Select } from 'antd';

const { Option } = Select;

export default function BargainingData() {

    let [main, setMain] = useState('')
    let [bar, setBar] = useState('')

    let [dataTime, setDataTime] = useState([])
    let [dataMessage, setDataMessage] = useState([])

    let [activeDays, setActiveDays] = useState(7)

    const optionLine = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross',
                label: {
                    backgroundColor: '#6a7985'
                }
            }
        },
        legend: {
            data: ['Number of activities']
        },
        grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: true,
                data: dataTime
            }
        ],
        yAxis: [
            {
                type: 'value'
            }
        ],
        series: [
            {
                name: 'Number of activities',
                type: 'line',
                stack: 'Total',
                areaStyle: { color: '#DCE7FB' },
                emphasis: {
                    focus: 'series'
                },
                itemStyle: {
                    normal: {
                        color: '#6293EE',
                        lineStyle: {
                            color: '#588DEC'
                        }
                    }
                },
                data: dataMessage
            }
        ]
    };

    const optionBar = {
        tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                return params[0].name + "<br>" + "数据:" + params[0].data;
            }
        },
        color: "#5087EC",
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                // data: [120, 200, 150, 80, 70, 110, 130],
                data: [],
                type: 'bar'
            }
        ]
    };


    useEffect(() => {
        var chartDomLine = document.getElementById('dataIndex-top');
        // var chartDomBar = document.getElementById('dataIndex-bottom');
        setMain(chartDomLine)
        // setBar(chartDomBar)
    }, [])

    // 获取折线图数据
    const mainEcharts = () => {
        let params = {
            days: activeDays
        }

        bargain_statistics(params).then((res) => {
            let Time = []
            let Message = []
            res.data.data.map((item, index) => {
                Time.push(item.date)
                Message.push(item.count)
            })
            setDataTime(Time)
            setDataMessage(Message)
        })
    }

    useEffect(() => {
        mainEcharts()
    }, [activeDays])

    // 选择器选择日期
    const handleChange = (value) => {
        setActiveDays(value)
    }

    if (main !== "") {
        var myChartLine = echarts.init(main);
        myChartLine.resize({ height: '400px' })
        myChartLine.setOption(optionLine);
        window.addEventListener("resize", function () {
            myChartLine.resize(); //图表自适应的一个方法
        });
    }

    if (bar !== "") {
        var myChartBar = echarts.init(bar);
        myChartBar.resize({ height: '400px' })
        myChartBar.setOption(optionBar);
        window.addEventListener("resize", function () {
            myChartBar.resize(); //图表自适应的一个方法
        });
    }

    return (
        <div className="dataIndex">
            <div className='select-time'>
                    <Select defaultValue="Last 7 days" style={{ width: 200 }} onChange={handleChange} popupClassName="select-times">
                        <Option value="7">Last 7 days</Option>
                        <Option value="30">Last 30 days</Option>
                        <Option value="180">Last 6 months</Option>
                        <Option value="360">Last year</Option>
                    </Select>
            </div>
            <div id="dataIndex-top">

            </div>
            {/* <div id="dataIndex-bottom">

            </div> */}
        </div>
    )
}