import './login.scss'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Logo_red from '@/assets/images/login_logo_red@2x.png'
import Shopify from '@/assets/images/login_shopify_logo@2x.png'

import { user_login } from '@/request/api'

import { Form, Input, Button } from 'antd';

const NormalLoginForm = () => {

  const onFinish = (values) => {

    let params = {
      shop : values.username+'.myshopify.com'
    }

    user_login(params).then(res => {
      window.location.href=res.data.url
    })

    sessionStorage.setItem("key","/Index");
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      onFinish={onFinish}
    >
      <Form.Item
        name="username"
        rules={[
          {
            required: true,
            message: 'Please input your Username!',
          },
        ]}
      >
        <Input
          className='user-input'
          addonAfter=".myshopify.com"
          placeholder="Store name"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Login With Shopify
        </Button>
      </Form.Item>
    </Form>
  );
};

export default function Login() {

  const history = useHistory();

  const pushTerms = ()=>{
    history.push('/terms')
  }
    return(
      <div className='main'>
        <div className='form-main'>
          <div className='form-img'>
            <img src={Logo_red} alt='' />
          </div>
          <div className='title'>
            Welcome back!
          </div>
          <NormalLoginForm />
          <div className='titlelast'>
            Don't have a <img src={Shopify} />Shopify store yet? <a href='https://shopify.com/' target="_blank">Create store</a>
          </div>
          <div onClick={pushTerms} className='footer'>
            Terms of Service • Privecy Policy
          </div>
        </div>
      </div>
    )
}