import './terms.scss'
import Logo from '@/assets/images/home_logo.png'
import People from '@/assets/images/home_tab_people@2x.png'

import React, { useState, useEffect } from 'react'
import { Layout, Menu, Dropdown, Modal } from 'antd';

import { useHistory } from 'react-router-dom'
import { user_name } from '@/request/api'

const { confirm } = Modal;
const { Header, } = Layout;

export default function Terms() {

    let [userName, setuserName] = useState('');
    const history = useHistory();

    const onClick = ({ key }) => {
        if (key === '4') {
            // 移除token，跳转到登录界面
            localStorage.removeItem("token");
            history.push("/login")
        }
        if (key === '2') {
            showConfirm()
          }
    };

    function showConfirm() {
        confirm({
          title: <div>
            <p>Please send your questions to email:</p>
            <div className='help'>2711749880@qq.com</div>
          </div>,
          onOk() {
            // active(mode)
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
      }

    const getIndex = () => {
        history.push('/')
    }

    const menu = (
        <Menu className='menu-style' onClick={onClick}>
            {/* <Menu.Item key="1">set up</Menu.Item> */}
            <Menu.Item key="2">help</Menu.Item>
            {/* <Menu.Item key="3">bill</Menu.Item> */}
            <Menu.Item key="4">sign out</Menu.Item>
        </Menu>
    );

    useEffect(async () => {
        if (localStorage.getItem("token")) {
            const result = await user_name()
            setuserName(() => {
                return result.data.data.shop_prefix
            })
        } else {
            setuserName(() => {
                return ''
            })
        }
    }, []);

    return (
        <Layout>
            <Header className="header">
                <div className="header-left">
                    <div className="logo">
                        <img onClick={getIndex} src={Logo} alt=''></img>
                    </div>
                </div>
                <div className="header-right">
                    <div className="head-portrait">
                        <img src={People} alt=''></img>
                    </div>
                    <Dropdown className='head-select' overlay={menu}>
                        <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            {userName}
                        </span>
                    </Dropdown>
                </div>
            </Header>
            <Layout className='Terms'>
                <div className='Terms-main'>
                    <div className='title'>
                        Price Ninja Terms of Service
                    </div>
                    <div className='Terms-text'>
                        <p>
                            "Price Ninja" provides "Create a bargaining campaign for your product" to merchants who use Shopify to power their stores. This Privacy Policy describes how personal information is collected, used, and shared when you install or use the App in connection with your Shopify-supported store.
                        </p>

                        <p>Personal Information the App Collects</p>

                        <p>
                            When you install the App, we are automatically able to access certain types of information from your Shopify account: Commodity information, transaction order, customer information.
                            Additionally, we collect the following types of personal information from you and/or your customers once you have installed the App: Information about you and others who may access the App on behalf of your store, such as phone number.
                        </p>

                        <p>
                            How Do We Use Your Personal Information?
                        </p>

                        <p>
                            We use the personal information we collect from you and your customers in order to provide the Service and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize or improve the App; and Provide you with information or advertising relating to our products or services.
                        </p>

                        <p>
                            Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
                        </p>

                        <p>
                            Your Rights If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
                        </p>

                        <p>
                            Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above.
                        </p>

                        <p>
                            Data Retention When you place an order through the Site, we will maintain your Order Information for our records, We will retain information about the product for which you have initiated a bargaining campaign, unless and until you ask us to delete this information.
                        </p>

                        <p>
                            Changes We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
                        </p>

                        <p>
                            Contact Us For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@thepriceninja.com.
                        </p>
                    </div>
                </div>
            </Layout>
        </Layout>
    )
}
