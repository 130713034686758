import './index.scss'
import React, { useContext } from 'react'
import { FatherContext } from '@/FatherContext'

import Close from '@/assets/images/popup_close.png'

export default function BargainingRules(props) {

    let rulesWindow = useContext(FatherContext).rulesWindow

    const hideDiscount = () => {
        props.setRulesWindow('none')
    }


    return (
        <div className="discount-mask" style={{ display: rulesWindow }}>
            <div className="rules-content">
                <div className='rules-close'>
                    <span onClick={hideDiscount}>
                        <img src={Close} alt='' />
                    </span>
                </div>
                <div className='title'>
                    Activity rules
                </div>
                <div className='title-data'>
                    <p>
                        1. Each friend can only bargain once, and the amount of each bargain is random
                    </p>
                    <p>
                        2. After reaching the maximum amount of bargaining, the initiator can use the discount to buy goods
                    </p>
                    <p>
                        3. Please complete the bargaining task within the specified time, otherwise the bargaining task will fail
                    </p>
                    <p>
                        4. You can repeatedly initiate a bargain task for a commodity
                    </p>
                    <p>
                        5. Friends who help bargain can get corresponding discounts from the store, and the friends page can launch bargain activities
                    </p>
                </div>
            </div>
        </div>
    )
}