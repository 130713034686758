import './index.scss'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Radio, DatePicker, Space, Input } from 'antd';
import moment from 'moment';

import { create_activities } from '@/request/api'

const { RangePicker } = DatePicker;

function disabledDate(current) {
    // 设置不能选择今天之前的时间
    return current && current < moment().subtract(1, 'days').endOf('day')
}


// let defaultTime = moment().format("HH:mm:ss")
// console.log(moment().format("HH:mm:ss"));

export default function Edit(props) {
    var zone = new Date().getTimezoneOffset() * 60 * 1000

    let data = {}
    data.fixed_percentage = 0
    data.fixed_value = 0
    data.start_time = ''
    data.end_time = ''
    data.bargain_min = 0
    data.bargain_max = 0
    data.time_limit = 0

    let products = localStorage.getItem("products")
    let new_products = JSON.parse(products)
    var params = []
    new_products.map((item, index) => {
        var obj = {}
        obj = Object.assign(item, data);
        params.push(obj)
        return ''
    })

    if (params.length === 1) {
        var productsQuantity = 1
        var choiceValue = 2
    } else {
        var choiceValue = 1
    }


    let topname = '> Select products';
    let editItem = '> Edit the campaign';
    // 砍价固定折扣
    let [discountvalue, setdiscountValue] = useState('0');
    let [discountdisplay, setDiscountdisplay] = useState({ display: 'none' })
    let [beforevalue, setbeforeValue] = useState('0')
    let [aftervalue, setafterValue] = useState('0')
    let [aftervaluedisplay, setAftervaluedisplay] = useState({ display: 'none' })
    let [minutes, setMinutes] = useState('0')
    let [hoursdisplay, setHoursdisplay] = useState({ display: 'none' })
    // 获取砍价限时
    let [timelimit, setTimelimit] = useState('0')
    let [starttime, setStarttime] = useState('')
    let [endtime, setEndtime] = useState('')
    // 批量编辑或者单项编辑
    const [value, setValue] = React.useState(choiceValue);
    const [valueMax, setValueMax] = React.useState(1);
    // 砍价固定金额
    const [maxValue, setMaxValue] = useState('0')
    // 已选商品列表
    let [productslist, setProductslist] = useState(params)
    // 最大砍价金额提示
    let [maxMessage, setMaxMessage] = useState('0')

    // let [quantity, setQuantity] = useState(productsQuantity)

    // 选择是批量编辑还是单项编辑
    const onChange = e => {
        console.log('选择批量编辑还是单项编辑');
        setValue(e.target.value);
    };
    // 选择是固定金额还是固定折扣
    const onChangeMax = (e, index) => {
        setValueMax(e.target.value)
        setProductslist(() => {
            if (e.target.value === 1) {
                productslist[index.index].fixed_percentage = 0;
            }
            if (e.target.value === 2) {
                productslist[index.index].fixed_value = 0;
            }
            return productslist
        })
    }

    // 批量编辑固定折扣
    const onChangediscounts = e => {
        let value = e.target.value
        const reg = /^\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            if (Number(value) > 100) {
                setDiscountdisplay({ display: 'block' })
                value = 100
            } else {
                setDiscountdisplay({ display: 'none' })
            }
            setMaxMessage(() => {
                let arrPrice = []
                productslist.map((item, index) => {
                    arrPrice.push(item.price)
                    return ''
                })
                let maxPrice = Math.max.apply(null, arrPrice)
                let Price = Math.floor((maxPrice / 100 * (value / 100)) * 100) / 100
                return Price
            })
            setdiscountValue(value)
        }
    }
    // 批量编辑离焦触发事件转化为数字类型
    const onBlurdiscounts = () => {
        setdiscountValue(Number(discountvalue))
    }

    // 批量编辑编辑砍价区间前
    const onChangeBefores = e => {
        let value = e.target.value
        const reg = /^\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            if (Number(value) > aftervalue) {
                setAftervaluedisplay({ display: 'block' })
                value = 0
            } else {
                setAftervaluedisplay({ display: 'none' })
            }
            setbeforeValue(value)
        }
    }
    // 批量编辑离焦触发事件转化为数字类型
    const onBlurBefores = () => {
        setbeforeValue(Number(beforevalue))
    }

    // 批量编辑编辑砍价区间后
    const onChangeAfters = e => {
        let value = e.target.value
        const reg = /^\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            if (Number(value) > maxMessage) {
                setAftervaluedisplay({ display: 'block' })
                value = maxMessage
            } else {
                setAftervaluedisplay({ display: 'none' })
            }
            setafterValue(value)
        }
    }
    // 批量编辑离焦触发事件转化为数字类型
    const onBlurAfters = () => {
        setafterValue(Number(aftervalue))
    }

    // 单独编辑固定折扣
    const onChangediscount = (e, index) => {
        let value = e.target.value
        const reg = /^\d*(\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            value = Number(value)
            if (value > 100) {
                value = 100
            }
            setdiscountValue(value)
        }
        setProductslist(() => {
            if (valueMax === 1) {
                productslist[index.index].fixed_value = 0;
            }
            if (valueMax === 2) {
                productslist[index.index].fixed_percentage = value;
            }
            return productslist
        })
    }

    // 固定金额
    const onChangeMaxValue = (e, index) => {
        let value = e.target.value
        const reg = /^\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            setMaxValue(value)
        }
        setProductslist(() => {
            if (valueMax === 1) {
                productslist[index.index].fixed_value = value;
            }
            if (valueMax === 2) {
                productslist[index.index].fixed_percentage = 0;
            }
            return productslist
        })
    }


    // 获取活动开始时间和结束时间
    function onChangeTime(dates, dateStrings) {
        //   let createTime =moment(item.create_time).valueOf() + zone * 1
        //   item.create_time=moment(createTime).format('YYYY-MM-DD HH:mm:ss')
        setStarttime(() => {
            let startTime = moment(dateStrings[0]).valueOf() + zone * 1
            return moment(startTime).format('YYYY-MM-DD HH:mm:ss')
        })
        setEndtime(() => {
            let endTime = moment(dateStrings[1]).valueOf() + zone * 1
            return moment(endTime).format('YYYY-MM-DD HH:mm:ss')
        })
    }

    const history = useHistory();

    const PreviousStep = () => {
        // history.push('/Cut/Create')
        window.history.back();//去另一个页面不刷新
    }

    const handleClick = () => {

        // 如果为批量编辑的时候
        if (value === 1) {
            let data = {}
            data.fixed_percentage = Number(discountvalue)
            data.fixed_value = maxValue * 100
            data.start_time = starttime
            data.end_time = endtime
            data.bargain_min = beforevalue * 100
            data.bargain_max = aftervalue * 100
            data.time_limit = Number(timelimit)
            // history.push('/Cut')
            // 移除本地数据
            // localStorage.removeItem("products");
            let products = localStorage.getItem("products")
            let new_products = JSON.parse(products)
            var params = []
            new_products.map((item, index) => {
                var obj = {}
                obj = Object.assign(item, data);
                params.push(obj)
                return ''
            })
        }
        if (value === 2) {
            productslist.map((item, index) => {
                item.bargain_max = item.bargain_max * 100
                item.bargain_min = item.bargain_min * 100
                item.fixed_value = item.fixed_value * 100
                item.time_limit = Number(timelimit)
                item.start_time = starttime
                item.end_time = endtime

                return productslist
            })
            params = productslist
        }
        // 调用创建砍价活动接口
        create_activities(params).then((res) => {
            if (res) {
                // 移除本地数据
                // localStorage.removeItem("products");
                history.push('/Cut')
            } else {
                params.map((item, index) => {
                    item.bargain_max = item.bargain_max / 100
                    item.bargain_min = item.bargain_min / 100
                    item.fixed_value = item.fixed_value / 100
                    item.time_limit = Number(timelimit)
                    item.start_time = starttime
                    item.end_time = endtime
                    return params
                })
            }
        }).catch((err) => {

        })
    }

    // 单独编辑砍价区间前
    const onChangeBefore = (e, index) => {
        let value = e.target.value
        const reg = /^\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            setbeforeValue(value)
        }
        setProductslist(() => {
            productslist[index.index].bargain_min = value;
            return productslist
        })
    }
    // 单独编辑砍价区间后
    const onChangeAfter = (e, index) => {
        let value = e.target.value
        const reg = /^\d*(\.\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            setafterValue(value)
        }
        setProductslist(() => {
            productslist[index.index].bargain_max = value;
            return productslist
        })
    }

    const onChangeMinutes = e => {
        let value = e.target.value
        value = Number(value)
        const reg = /^\d*(\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            if (value > 1440) {
                setHoursdisplay({ display: 'block' })
                value = 1440
            } else {
                setHoursdisplay({ display: 'none' })
            }

            setMinutes(value)

            setTimelimit(() => {
                return value
            })
        }
    }

    const onBlurMinutes = (e) => {
        let value = e.target.value
        value = Number(value)
        const reg = /^\d*(\d*)?$/;
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            if (value > 1440) {
                setHoursdisplay({ display: 'block' })
                value = 1440
            } else if (value < 30) {
                setHoursdisplay({ display: 'block' })
                value = 30
            } else {
                setHoursdisplay({ display: 'none' })
            }

            setMinutes(value)

            setTimelimit(() => {
                return value
            })
        }
    }


    return (
        <div>
            <div className="create-topbar2">
                <div className="create-left">
                    <p className="create-clickbar">Campaign</p>
                    <span>{topname}</span>&nbsp;
                    <span>{editItem}</span>
                </div>
                <div className='btn'>
                    <div onClick={PreviousStep} className="create-btn-first">
                        Previous step
                    </div>
                    <div onClick={handleClick} className="create-btn">
                        Create
                    </div>
                </div>
            </div>
            <div className='already-selected'>
                Selected products
                <div className='products-name'>
                    {
                        productslist.map((item, index) => {
                            return item.title + ';'
                        })
                    }
                </div>
            </div>
            <div className='edit-radio'>
                <Radio.Group onChange={onChange} value={value}>
                    {
                        productsQuantity ? (<Radio value={2}>Single editor</Radio>) : (<>
                            <Radio value={1}>The batch editor</Radio>
                            <Radio value={2}>Single editor</Radio></>)
                    }

                </Radio.Group>
            </div>
            {
                value === 1 ? (
                    <div className='edit-main'>
                        <div className='edit-main-input'>
                            <p className='before-input'>Bargaining ceiling：</p>
                            <div className='discount'>
                                <div className='discount-top'>
                                    <span className='discount-span'>Fixed discount</span>
                                    -
                                    <Input
                                        size="small"
                                        value={discountvalue}
                                        onChange={onChangediscounts}
                                        onBlur={onBlurdiscounts}
                                    />%
                                </div>
                                <div className='message' style={discountdisplay}>The maximum value is 100</div>
                            </div>
                        </div>
                        <div className='edit-main-input'>
                            <p className='before-input'>Each bargaining interval：</p>
                            <div className='discount'>
                                <div className='discount-top'>
                                    $<Input
                                        size="small"
                                        value={beforevalue}
                                        onChange={onChangeBefores}
                                        onBlur={onBlurBefores}
                                    />  ~  <Input
                                        size="small"
                                        value={aftervalue}
                                        onChange={onChangeAfters}
                                        onBlur={onBlurAfters}
                                    />
                                </div>
                                <div className='message' style={aftervaluedisplay}>The minimum interval of bargaining cannot exceed the maximum interval</div>
                            </div>
                            <div className='max-message'>No more than  {maxMessage}$</div>
                        </div>
                        <div className='edit-main-input'>
                            <p className='before-input'>Bargaining limit duration：</p>
                            <div className='discount'>
                                <div className='discount-top'>
                                    <Input
                                        className='input-time'
                                        size="small"
                                        value={minutes}
                                        onChange={onChangeMinutes}
                                        onBlur={onBlurMinutes}
                                    // onBlur={onBlurAfters}
                                    /> minutes
                                </div>
                                <div className='message' style={hoursdisplay}>Maximum 24 hours, minimum 30 minutes</div>
                            </div>
                        </div>
                        <div className='edit-main-input'>
                            <p className='before-input'>Activity time：</p>
                            <Space direction="vertical" size={12}>
                                <RangePicker
                                    size="small"
                                    onChange={onChangeTime}
                                    disabledDate={disabledDate}
                                    showTime={{
                                        hideDisabledOptions: true,
                                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                                    }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Space>
                        </div>
                    </div>
                ) : (
                    <div className='edit-main'>
                        <div className='edit-title'>
                            <p>Bargaining limit duration：</p>
                            <div className='discount'>
                                <div className='discount-top'>
                                    <Input
                                        className='input-time'
                                        size="small"
                                        value={minutes}
                                        onChange={onChangeMinutes}
                                        onBlur={onBlurMinutes}
                                    // onBlur={onBlurAfters}
                                    /> minutes
                                </div>
                                <div className='message' style={hoursdisplay}>Maximum 24 hours, minimum 30 minutes</div>
                            </div>
                        </div>
                        <div className='edit-title'>
                            <p>Activity time：</p>
                            <Space direction="vertical" size={12}>
                                <RangePicker
                                    size="small"
                                    onChange={onChangeTime}
                                    disabledDate={disabledDate}
                                    showTime={{
                                        hideDisabledOptions: true,
                                        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                                    }}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Space>
                        </div>
                        {
                            productslist.map((item, index) => {
                                return (
                                    //  砍价商品表格
                                    <div key={index} className='shop-table'>
                                        <img src={item.image} alt=''></img>
                                        <div className='shop-message'>
                                            <p>Item：{item.title}</p>
                                            <p>Price：${item.price / 100}</p>
                                        </div>
                                        {/* 编辑砍价信息 */}
                                        <div className='shop-edit'>
                                            <div className='cut-max'>
                                                <p>Bargaining ceiling</p>
                                                <div>
                                                    <Radio.Group onChange={e => onChangeMax(e, { index })} value={valueMax}>
                                                        <Space direction="vertical">
                                                            <Radio value={1}>
                                                                <span className='edit-span'>Fixed amount  -</span>
                                                                <Input
                                                                    size="small"
                                                                    value={item.fixed_value}
                                                                    onChange={e => onChangeMaxValue(e, { index })}
                                                                />
                                                            </Radio>
                                                            <Radio value={2}>
                                                                <span className='edit-span'>Fixed discount -</span>
                                                                <Input
                                                                    size="small"
                                                                    value={item.fixed_percentage}
                                                                    onChange={e => onChangediscount(e, { index })}
                                                                />%
                                                            </Radio>
                                                        </Space>
                                                    </Radio.Group>
                                                </div>
                                            </div>
                                            <div className='cut-section'>
                                                <p>Each bargaining interval</p>
                                                $<Input
                                                    size="small"
                                                    value={item.bargain_min}
                                                    onChange={e => onChangeBefore(e, { index })}
                                                // onBlur={onBlurBefores}
                                                />  ~  <Input
                                                    size="small"
                                                    value={item.bargain_max}
                                                    onChange={e => onChangeAfter(e, { index })}
                                                // onBlur={onBlurAfters}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            }
        </div>
    )
}