/**
 * 定义路由组件，将 auth 设置为 true，表示该路由需要权限校验
 */

import Home from "./pages/home/home";
import Login from "./pages/login/login";
import Terms from "./pages/terms/terms";
import Bargain from "./pages/bargain/bargain";
import Share from "./pages/share/share"

// import Error from "./pages/error";

export const routerMap = [
  { path: "/", name: "home", component: Home, auth: true },
  { path: "/Index", name: "index", component: Home, auth: true },
  { path: "/Cut", name: "cut", component: Home, auth: true },
  { path: "/Cut/Details", name: "details", component: Home, auth: true },
  { path: "/Cut/Shop", name: "shop", component: Home, auth: true },
  { path: "/Cut/Create", name: "create", component: Home, auth: true },
  { path: "/Cut/Edit", name: "edit", component: Home, auth: true },
  { path: "/Subscribe", name: "subscribe", component: Home, auth: true },
  { path: "/Theme", name: "theme", component: Home, auth: true },
  { path: "/terms", name: "Terms", component: Terms },
  { path: "/bargain/", name: "Bargain", component: Bargain },
  { path: "/share/", name: "Share", component: Share },
  { path: "/login", name: "Login", component: Login },
  { path: "/error", name: "error", component: Error },
];