import './index.scss'
import React, { useContext } from 'react'
import {FatherContext} from '@/FatherContext'

export default function Ranking() {

  // 排名下面是否显示页脚
  const shareflag = useContext(FatherContext).shareflag
  const Arr = useContext(FatherContext).ranking


  const nameFilter = (data) => {
    var reg = /^(.).+(.)$/g;
    return data.replace(reg, "$1***$2")
  }

  return (
    <div className='ranking-main'>
      <div className='ranking-head'>
        Ninja Ranking
      </div>
      <div className='ranking-rank'>
        <div className='raining-rank-main'>
          {
            Arr.map((item, index) => (
              <div key={index} className='ranking-user'>
                <div className='ranking-name'>
                  <span>{item.headPortrait}</span>
                  <span>{nameFilter(item.telephone)}</span>
                </div>
                <div className='ranking-price'>
                  {/* 判断前三名给图片，后面数据为默认 */}
                  {
                    index < 3 ? (
                      <span>
                        <img className='rankingImg' src={item.rank} alt='' />
                      </span>
                    ) : (
                      <span></span>
                    )
                  }
                  <span>${item.price}</span>
                </div>
              </div>
            ))
          }
          <div className='ranking-footer'>
            You need more friends to help you cut the price...
          </div>
        </div>
      </div>
      {/* 底部的内容 */}
      {
        shareflag === true?(
          <div className='share-footer'>
        <div className='footer-top'>
          All services are provided by Price Ninja.
        </div>
        <div className='footer-footer'>
          http://thepriceninja.com
        </div>
      </div>
        ):('')
      }
      {/* <div className='share-footer'>
        <div className='footer-top'>
          All services are provided by Price Ninja.
        </div>
        <div className='footer-footer'>
          http://thepriceninja.com
        </div>
      </div> */}
    </div>
  )
}