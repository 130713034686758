import './index.scss'
import React, { useState,useEffect } from 'react'

import BargainingRecord from '@/component/BargainingRecord/index'
import BargainingData from '@/component/BargainingData/index'
import { useHistory } from 'react-router-dom'

import { shop_status } from '@/request/api'

import { CloseOutlined } from '@ant-design/icons';

export default function Index() {

    let [clickbar, setClickbar] = useState('record');
    let [amessage, setAmessage] = useState(sessionStorage.getItem("mes"))

    const history = useHistory();

    useEffect(async () => {
        const res = await shop_status()
          if (res.data.is_vip === 1) {
              localStorage.setItem("is_vip", res.data.is_vip);
          } else {
              localStorage.setItem("is_vip", res.data.is_vip);
          }
    }, []);

    const handleClickRecord = () => {
        setClickbar(() => {
            return 'record'
        })
    }

    const handleClickMenu = () => {
        setClickbar(() => {
            return 'Data'
        })
    }

    const closeMessage = () => {
        sessionStorage.setItem("mes", 'none')
        setAmessage('none')
    }

    const appPush = () => {
        history.push('/Theme')
        // sessionStorage.setItem("key", "/Theme");
    }


    return (
        <div className="index">
            <div className='appearanceMmessage' style={{ display: amessage }}>You have not finished installing Price Ninja, please click <a onClick={appPush}>"Appearance"</a> to install it. If installed, please ignore this message!<span onClick={closeMessage}><CloseOutlined /></span></div>
            <div className="index-topbar">
                <p
                    onClick={handleClickRecord}
                    className={clickbar === "record" ? "clickbar" : "noclickbar"}
                >record</p>
                <p
                    onClick={handleClickMenu}
                    className={clickbar === "Data" ? "clickbar" : "noclickbar"}
                >Data</p>
            </div>
            {clickbar === "record" ? (
                <BargainingRecord />
            ) : (
                <BargainingData />
            )}
        </div>
    )
}