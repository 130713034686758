import './subscribe.scss'
import React, { useState,useEffect } from 'react'
import { shop_subscribe, sms_package, buy_sms_package } from '@/request/api'


import { Modal, Table, Button } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';

export default function Subscribe() {

    let [choose, setChoose] = useState('free')
    // 1为vip 0不是vip
    let isVip = localStorage.getItem("is_vip");

    let [SMSdata,setSMSdata]= useState([])

    const columns = [
        {
          dataIndex: 'smsNum',
          align: 'center',
        },
        {
          dataIndex: 'name',
          align: 'center',
        },
        {
          dataIndex: 'buy_click',
          align: 'center',
          render:(row)=>(
            <Button type="primary" onClick={()=>Buy_SMS(row)} >Buy now</Button>
          )
        },
    ];

    useEffect(()=>{
        sms_package().then(res=>{
            let SMSDATA = []
            res.data.data.forEach((item,index)=>{
                item.key = item.id
                item.smsNum = "$"+item.price.toString()
                item.buy_click = item.id
                SMSDATA.push(item)
            })
            setSMSdata(SMSDATA)
        })
        if(isVip === '1'){
            setChoose('pay') 
        }
    },[])

    const Buy_SMS= (SMS_num)=>{
        let params={
            package:SMS_num
        }
        buy_sms_package(params).then(res=>{
            window.location.href=res.data.confirmation_url
        })
    }

    const Free = () => {
        setChoose('free')
    }

    const Pay = () => {
        setChoose('pay')
        if(isVip === '0'){
            confirm()
        }
        if(isVip === '1'){
            confirm_Vip()
        }
    }

    // 订阅商品
    const SubscriptionProducts = () => {
        shop_subscribe().then((res) => {
            window.location.href=res.data.confirmation_url
        })
    }

    function confirm() {
        Modal.confirm({
          title: 'subscribe',
          icon: <InfoCircleTwoTone />,
          content: 'Are you sure you want to subscribe to this product?',
          onOk() {
            SubscriptionProducts()
          },
          onCancel() {
            setChoose('free')
          },
        });
    }

    function confirm_Vip(){
        Modal.confirm({
            title: 'subscribe',
            icon: <InfoCircleTwoTone />,
            content: 'You have subscribed to this product.',
            onOk() {
                
            }
        });
    }

    return (
        <div className='subscribe-main'>
            <div className='main'>
                <div className='header'>
                    <p className='title'>Package selection</p>
                    <p className='title-bar'>You can choose the corresponding package according to your team size and business needs</p>
                </div>
                <div className='subscribe'>
                    <div className='left'>
                        <div className='left-title'>Free</div>
                        <div className='left-main'>
                            <p>You can initiate a bargaining campaign on an item</p>
                            <p>Active timer</p>
                            <p>View activity data in real time</p>
                            <p>Statistical profits</p>
                        </div>
                        <div className='left-price'>
                            0.00 <span>USD/mo</span>
                        </div>
                        {
                            choose === 'free' ? (
                                <div onClick={Free} className='left-btn'>
                                    Selected
                                </div>
                            ) : (<div onClick={Free} className='left-choose'>
                                Change
                            </div>)
                        }
                    </div>
                    <div className='right'>
                        <div className='right-title'>Official Edition</div>
                        <div className='right-main'>
                            <p>You can create a bargaining campaign for all items</p>
                            <p>Active timer</p>
                            <p>View activity data in real time</p>
                            <p>Statistical profits</p>
                        </div>
                        <div className='right-price'>
                            <s>68.00</s> 18.00 <span>USD/mo</span>
                            <p>Paid Mounthly</p>
                        </div>
                        {
                            choose === 'free' ? (
                                <div onClick={Pay} className='choose'>
                                    Change
                                </div>
                            ) : (<div className='right-btn'>
                                Selected
                            </div>)
                        }
                    </div>
                </div>
                <div className='SMS_package'>
                    <div className='SMS_top'>
                        <h1>SMS package</h1>
                        Number of remaining SMS messages: <span>{sessionStorage.getItem("sms_num")}</span>
                    </div>
                    <Table pagination={false} showHeader={false} columns={columns} dataSource={SMSdata} size="middle" />
                </div>
                <div className="SMS_bottom"> </div>
            </div>
        </div>
    )
}