import './index.scss'
import React, { useContext } from 'react'
import {FatherContext} from '@/FatherContext'

import shopIcon from '@/assets/images/shop_white.png'

export default function Ranking () {

  const productArr = useContext(FatherContext).moreProduct
  const shopName = useContext(FatherContext).shopName

  const goProduct =()=>{
    window.location.href = `https://${shopName}.myshopify.com/`
  }

  return (
    <div className='product-main'>
      <div className='product-header'>
        More Product
      </div>
      <div className='product-product'>
        {
          productArr.map((item, index) => (
            <div key={index} className='product-main-img'>
              <div className='productImg'>
                <a href={item.handle}><img src={item.image} alt='' /></a>
              </div>
            </div>
          ))
        }
      </div>
      <div className='product-btn-main'>
        <div onClick={goProduct} className='product-btn-btn'>
          <img src={shopIcon} alt='' />
          <span>see more..</span>
        </div>
      </div>
      <div className='share-footer'>
        <div className='footer-top'>
          All services are provided by Price Ninja.
        </div>
        <div className='footer-footer'>
          http://thepriceninja.com
        </div>
      </div>
    </div>
  )
}