import './index.scss'
import React, { useState, useEffect } from 'react'
import { Input, Table } from 'antd';
import searchImg from '@/assets/images/home_search@2x.png'
import { cut_bargains } from '@/request/api'
import moment from 'moment-timezone';


export default function BargainingRecord() {

    var zone = -(new Date().getTimezoneOffset() * 60 * 1000)

    const columns = [
        {
            title: 'Time',
            dataIndex: 'start_time',
            align: 'center',
        },
        {
            title: 'Number',
            dataIndex: 'bargain_id',
            align: 'center',
        },
        {
            title: 'The originator',
            dataIndex: 'telephone',
            align: 'center',
        },
        {
            title: 'Participants',
            dataIndex: 'records',
            align: 'center',
            render: records => (
                <>
                    {records.map((tag, index) => {
                        return (
                            <div key={index}>
                                <span>
                                    {tag}
                                </span>
                            </div>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Product',
            dataIndex: 'title',
            align: 'center',
        },
        {
            title: 'State',
            dataIndex: 'state',
            align: 'center',
            render: state => (
                <>
                    {state.map((tag, index) => {
                        return (
                            <div key={index}>
                                {
                                    tag.condition === 'success' && tag.paid ? (<p style={{ "color": '#2C9B1F' }}>Bargain succeeded, user has purchased</p>) : tag.condition === 'success' && !tag.paid ? (<p style={{ "color": '#2C9B1F' }}>Successful bargaining</p>) : tag.condition === 'ing' && !tag.paid ? (<p>In bargaining, the remaining {tag.rest}%</p>) : (<p>Bargaining failed</p>)
                                }
                            </div>
                        );
                    })}
                </>
            ),
        }
    ]

    let [data, setData] = useState([])
    let [keyWord, setKeyWord] = useState('')

    useEffect(() => {
        cutData()
    }, [keyWord]);

    // 搜索框
    const onSearch = e => {
        if (e.keyCode === 13) {
            setKeyWord(e.target.value)
        }
    }

    const cutData = () => {
        let params = {
            page: 1,
            keyword: keyWord
        }

        cut_bargains(params).then((res) => {
            let result = []
            res.data.data.map((item, index) => {

                let state = []
                state.push()
                item.state = state

                item.key = item.bargain_id
                result.push(item)
            })
            let test = []
            result.map((item, index) => {
                let endTime = moment(item.end_time).valueOf()+zone*1
                let nowTime = moment().valueOf()
                let startTime = moment(item.start_time).valueOf()+zone*1
                item.start_time = moment(startTime).format('YYYY-MM-DD HH:mm:ss')
                if (endTime > nowTime && item.bargain_rest > 0) {
                    item.condition = 'ing'
                }
                if (endTime <= nowTime && item.bargain_rest === 0) {
                    item.condition = 'success'
                }
                if (endTime <= nowTime && item.bargain_rest > 0) {
                    item.condition = 'fail'
                }
                let state = [{
                    condition: item.condition,
                    paid: item.paid,
                    rest: Math.floor((item.bargain_rest / item.bargain_total) * 100 * 100) / 100
                }]
                state.push()
                item.state = state
                test.push(item)
            })

            setData(test)
        })
    }

    return (
        <div className="index-main">
            <div className="index-search">
                <div className="search-input">
                    <img src={searchImg} alt=""></img>
                    <Input className='input-main' placeholder="Search ID or phone number" onKeyDown={e => onSearch(e)} />
                </div>
            </div>
            <div className="index-table">
                <Table
                    columns={columns}
                    dataSource={data}
                    bordered
                />
            </div>
        </div>
    )
}