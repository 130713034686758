import Index from './pages/index/index'
import Cut from './pages/cut/cut'
import Subscribe from './pages/subscribe/subscribe'
import Theme from './pages/theme/theme'
import "antd/dist/antd.css";
import Icon from '@ant-design/icons';

const StopSvg = () => (
  <svg t="1648429912637" fill="currentColor" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="847" width="14" height="14"><path d="M511.763838 37.785978L50.774908 347.158672v558.760147c0 43.926199 35.896679 80.295203 80.295203 80.295203h761.859778c43.926199 0 80.295203-35.896679 80.295203-80.295203V347.158672L511.763838 37.785978z" p-id="848"></path></svg>
);

const HeartSvg = () => (
  <svg t="1648430039883" fill="currentColor" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="875" width="14" height="14"><path d="M406.4715 452.932057H46.927497C21.245782 452.932057 0.23347 431.919745 0.23347 406.23803V46.694026C0.23347 21.012312 21.245782 0 46.927497 0h359.544003c25.681715 0 46.694026 21.012312 46.694027 46.694026v359.544004c0 25.681715-21.012312 46.694026-46.694027 46.694027zM773.019608 438.456908l-187.709986-187.709986c-13.541268-13.541268-13.541268-35.48746 0-48.561787l187.709986-187.709987c13.541268-13.541268 35.48746-13.541268 48.561787 0l187.709987 187.709987c13.541268 13.541268 13.541268 35.48746 0 48.561787l-187.709987 187.709986c-13.074327 13.074327-35.02052 13.074327-48.561787 0zM406.4715 1024H46.927497c-25.681715 0-46.694026-21.012312-46.694027-46.694026v-359.544004c0-25.681715 21.012312-46.694026 46.694027-46.694027h359.544003c25.681715 0 46.694026 21.012312 46.694027 46.694027v359.544004c0 25.681715-21.012312 46.694026-46.694027 46.694026zM977.072503 1024h-359.544003c-25.681715 0-46.694026-21.012312-46.694027-46.694026v-359.544004c0-25.681715 21.012312-46.694026 46.694027-46.694027h359.544003c25.681715 0 46.694026 21.012312 46.694027 46.694027v359.544004c0 25.681715-21.012312 46.694026-46.694027 46.694026z" p-id="876"></path></svg>
);

const PandaSvg = () => (
  <svg t="1648429816394" fill="currentColor" viewBox="0 0 1401 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="833" width="16" height="16"><path d="M0 0v1024h1370.307225V0H0z m813.853066 599.975713H144.242866V88.908318h669.6102v511.067395z" p-id="834"></path></svg>
);

const PlanSvg = () => (
  <svg t="1648433343853" fill="currentColor" viewBox="0 0 1309 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="909" width="17" height="17"><path d="M206.823271 141.628979L320.351262 732.873765h479.964874" p-id="910"></path><path d="M800.316136 766.594951H292.250274L173.664105 147.811196l66.318332-12.364434L348.45225 699.15258h451.863886z" p-id="911"></path><path d="M750.858397 732.873765h430.507135l88.237102-460.294182s40.465423-130.950604-86.551042-130.950604H206.823271" p-id="912"></path><path d="M1209.46652 766.594951h-458.608123v-67.442371h402.406147L1237.005488 264.149286l0.56202-1.686059c3.372119-11.802415 10.116356-50.019759-4.496158-70.25247-8.430296-11.240395-25.290889-17.422613-49.457739-17.422612H206.823271v-67.442371h976.228321c58.450055 0 88.799122 24.16685 103.973655 44.961581 35.407245 47.771679 19.108672 113.527991 15.174533 127.578485l-92.73326 486.709111z" p-id="913"></path><path d="M176.474204 163.54775C168.605928 79.806806 118.024149 68.566411 108.469813 67.442371H0V0h112.403952c40.465423 2.810099 119.710209 39.341383 131.512623 156.803513l-67.442371 6.744237z" p-id="914"></path><path d="M486.709111 933.514819m-90.485181 0a90.485181 90.485181 0 1 0 180.970362 0 90.485181 90.485181 0 1 0-180.970362 0Z" p-id="915"></path><path d="M909.909989 933.514819a90.485181 90.485181 0 1 0 180.970362 0 90.485181 90.485181 0 1 0-180.970362 0Z" p-id="916"></path></svg>
)

const StopIcon = props => <Icon component={StopSvg} {...props} />;

const HeartIcon = props => <Icon component={HeartSvg} {...props} />;

const PandaIcon = props => <Icon component={PandaSvg} {...props} />;

const PlanIcon = props => <Icon component={PlanSvg} {...props} />;

let routes = [
  {
    path: '/Index',
    component: Index,
    title: 'Home',
    icon: <StopIcon />,
    exact: true
  },
  {
    path: '/Cut',
    component: Cut,
    title: 'Campaign',
    icon: <HeartIcon />,
    exact: true
  },
  {
    path: '/Theme',
    component: Theme,
    title: 'Appearance',
    icon: <PandaIcon />,
    exact: true
  },
  {
    path: '/Subscribe',
    component: Subscribe,
    title: 'Plan',
    icon: <PlanIcon />,
    exact: true
  },
]

export default routes  
