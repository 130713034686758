import './index.scss'
import React from 'react'

export default function Shop() {

    return(
        <div>
            Shop
        </div>
    )
}