import './index.scss'
import React, { useState, useEffect } from 'react'
import { bargain_data, stop_activities, query_activities } from '@/request/api'
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';

const { confirm } = Modal;

export default function HotSelling(props) {

    var tablelist = JSON.parse(sessionStorage.getItem("details"))

    var topname = '>'

    let [count, setCount] = useState('')
    let [bargainData, setBargainData] = useState('')

    useEffect(() => {
        bargain()
    }, [])

    const timeState = () => {
        // 将日期转化为时间戳
        let startTime = moment(tablelist.start_time).valueOf()
        let endTime = moment(tablelist.end_time).valueOf()
        // 获取当前时间戳
        let nowTime = Number(moment().format("x"))

        if (startTime > nowTime && nowTime < endTime) {
            count = "Not started"
            setCount(count)
        }
        if (startTime < nowTime && nowTime < endTime) {
            count = "In progress"
            setCount(count)
        }
        if (startTime < nowTime && nowTime >= endTime) {
            count = "Ended"
            setCount(count)
            if (startTime === endTime) {
                count = "Cancelled"
                setCount(count)
            }
        }
    }

    const btnActive = (e) => {
        let activeState = e.target.innerHTML
        if (activeState === "End activity") {
            let mode = 'stop'
            // active(mode)
            showConfirm(mode,'end')
        }
        if (activeState === "Start now") {
            let mode = 'start'
            showConfirm(mode,'start')
        }
        if (activeState === "Cancel activity") {
            let mode = 'cancel'
            showConfirm(mode,'cancel')
        }
    }

    // 查询活动
    const bargain = () => {
        let params = {
            activity_id: tablelist.activity_id
        }
        bargain_data(params).then((res) => {
            setBargainData(res.data.data)
            timeState()
        })
    }

    // 更改活动状态
    const active = (mode) => {
        let data = {
            mode: mode,
            activity_id: tablelist.activity_id
        }
        stop_activities(data).then((res) => {
            queryActivities()
        })
    }

    // 点击改变状态后改变数据查询活动
    const queryActivities = () => {
        let params = {
            id: tablelist.activity_id
        }
        query_activities(params).then((res) => {
            tablelist = res.data.data[0]
            timeState()
        })
    }

    function showConfirm(mode,state) {
        confirm({
          title: `Are you sure to ${state} this activity?`,
          icon: <ExclamationCircleOutlined />,
          onOk() {
            active(mode)
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
    }


    return (
        <div>
            <div className="hot-topbar">
                <p className="hot-clickbar">Bargaining activities</p>
                <span>{topname}   {tablelist.create_time}</span>
            </div>
            <div className="hot-header">
                <div className='create-time'>Creation time：{tablelist.create_time}</div>
                <div className='messages'>
                    <div className='msg-message'>Activity information</div>
                    <div className='msg-time'>Bargaining limit duration：{tablelist.time_limit} minutes</div>
                    <div className='msg-times'>Activity time：{tablelist.start_time} ~ {tablelist.end_time}</div>
                    <div className='active-state'>
                        <div>Active state：{count}</div>
                        {
                            count === "In progress" ? (<div className='active-btn' onClick={btnActive}>End activity</div>) : count === "Not started" ? (<div className='active-nostart'>
                                <div className='active-btn' onClick={btnActive}>Start now</div>
                                <div className='active-btn' onClick={btnActive}>Cancel activity</div>
                            </div>
                            ) : ('')
                        }
                    </div>
                </div>
            </div>
            <div className="hot-main">
                <div className='active-data'>
                    Activity data
                </div>
                <div className="main">
                    <div>
                        <span>{bargainData.activity_all_users_count}</span>
                        <span>Total number of participants</span>
                    </div>
                    <div>
                        <span>{bargainData.bargain_times}</span>
                        <span>Bargaining initiation times</span>
                    </div>
                </div>
                <div className="main">
                    <div>
                        <span>{bargainData.bargain_success_times}</span>
                        <span>Number of successful bargaining</span>
                    </div>
                </div>
            </div>
            <div className='activity-goods'>
                <div className='top'>Participating products</div>
                <div>
                    {/* 参加活动的商品循环 */}
                    <div className='goods'>
                        <div className='goods-left'>
                            <div className='goods-img'>
                                <img src={tablelist.image} alt='' />
                            </div>
                            <div className='goods-world'>
                                <span>
                                    Item：{tablelist.title}
                                </span>
                                <span>
                                    Price：${tablelist.price / 100}
                                </span>
                            </div>
                        </div>
                        <div className='goods-right'>
                            <span>Bargaining ceiling：
                                -${tablelist.fixed_value / 100}</span>
                            <span>Each bargaining interval：${tablelist.bargain_min / 100}~${tablelist.bargain_max / 100}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}