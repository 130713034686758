import React, { Fragment } from 'react'
import './App.scss';

import { Switch } from 'react-router-dom'
import FrontendAuth from "./FrontendAuth";
import {routerMap} from "./routerMap";


function App() {

  return (
    <Fragment>
            {/*只匹配一个，匹配成功就不往下匹配，效率高*/}
            <Switch>
                <FrontendAuth routerConfig={routerMap}/>
            </Switch>
     </Fragment>
  )
}

export default App;