/**
 * 路由守卫校验
 */
import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { shop_subscribed, once_charge } from '@/request/api'


class FrontendAuth extends Component {
    constructor(props) {
        super(props);
    }

    //  获取url中的token
    getParameter(name) {
        var reg = new RegExp("(^|\\?|&)" + name + "=([^&]*)(\\s|&|$)", "i");
        return reg.test(window.location.href) ? unescape(RegExp.$2.replace(/\+/g, " ")) : "";
    };


    render() {
        const { routerConfig, location } = this.props;
        const { pathname } = location;

        const confClass = this.getParameter('token');
        if (confClass) {
            localStorage.setItem("token", confClass);
        }

        const isLogin = localStorage.getItem("token");


        //  判断订阅的id
        const chargeId = this.getParameter('charge_id');
        const once = this.getParameter('once');
        if(chargeId && once==='true'){
            sessionStorage.setItem("key", "/Index");
            let params = {
                charge_id: chargeId
            }
            once_charge(params)
        }
        if (chargeId && !once) {
            sessionStorage.setItem("key", "/Index");
            let params = {
                charge_id: chargeId
            }
            shop_subscribed(params)
        }



        //  访问不需要权限校验的路由
        const targetRouterConfig = routerConfig.find(
            (item) => item.path === pathname
        );

        if (targetRouterConfig && !targetRouterConfig.auth && !isLogin) {
            const { component } = targetRouterConfig;
            return <Route exact path={pathname} component={component} />;
        }
        if (isLogin) {
            // 如果是登陆状态，想要跳转到登陆，重定向到主页
            if (pathname === "/login") {
                return <Redirect to="/" />;
            } else {
                // 如果路由合法，就跳转到相应的路由
                if (targetRouterConfig) {
                    return (
                        <Route path={pathname} component={targetRouterConfig.component} />
                    );
                } else {
                    // 如果路由不合法，重定向到 404 页面
                    return <Redirect to="/error" />;
                }
            }
        } else {
            // 非登陆状态下，当路由合法时且需要权限校验时，跳转到登陆页面，要求登陆
            if (targetRouterConfig && targetRouterConfig.auth) {
                const confClass = this.getParameter('token');

                if (targetRouterConfig.path === '/' && confClass) {
                    //  存储token
                    localStorage.setItem("token", confClass);
                }
                return <Redirect to="/login" />;
            } else {
                // 非登陆状态下，路由不合法时，重定向至 404
                return <Redirect to="/error" />;
            }
        }
    }
}

export default FrontendAuth;
