import './home.scss'
import Logo from '@/assets/images/home_logo.png'
import People from '@/assets/images/home_tab_people@2x.png'

import React, { useState, useEffect } from 'react'
import { Layout, Menu, Dropdown, Modal } from 'antd';

import Index from "../index/index"
import Cut from "../cut/cut"
import Subscribe from '../subscribe/subscribe'
import Theme from '../theme/theme'
import ActivityDetails from "@/component/ActivityDetails/index"
import Shop from "@/component/Shop/index"
import Create from '@/component/Create/index'
import Edit from '@/component/Edit/index'
import routers from "@/routers"
import { Route, Switch, useHistory } from 'react-router-dom'
import { user_name, shop_status } from '@/request/api'

import { ExclamationCircleOutlined } from '@ant-design/icons';
const { confirm } = Modal;

const { Header, Content, Sider } = Layout;

export default function Home() {
  if (!sessionStorage.getItem("key")) {
    sessionStorage.setItem("key", "/Index");
  }
  let [userName, setuserName] = useState('');
  let keyWorls = sessionStorage.getItem("key");
  const history = useHistory();
  const handleClick = (e) => {
    sessionStorage.setItem("key", e.keyPath[0]);
    var key = sessionStorage.getItem("key")
    history.push(key)
  }

  const pushTerms = () => {
    history.push('/terms')
  }

  const onClick = ({ key }) => {
    if (key === '4') {
      // 移除token，跳转到登录界面
      localStorage.removeItem("token");
      localStorage.removeItem("is_vip");
      history.push("/login")
    }
    if (key === '2') {
      showConfirm()
    }
  };

  function showConfirm() {
    confirm({
      title: <div>
        <p>Please send your questions to email:</p>
        <div className='help'>2711749880@qq.com</div>
      </div>,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        // active(mode)
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  }

  const menu = (
    <Menu className='menu-style' onClick={onClick}>
      {/* <Menu.Item key="1">set up</Menu.Item> */}
      <Menu.Item key="2">help</Menu.Item>
      {/* <Menu.Item key="3">bill</Menu.Item> */}
      <Menu.Item key="4">sign out</Menu.Item>
    </Menu>
  );

  const getIndex = () => {
    history.push('/')
  }

  const Buy_SMS = () => {
    sessionStorage.setItem("key", '/Subscribe');
    history.push('/Subscribe')
  }

  useEffect(async () => {
    const result = await user_name()
    sessionStorage.setItem("userName", result.data.data.shop_prefix);
    sessionStorage.setItem('sms_num',result.data.data.sms_num)
    setuserName(() => {
      return result.data.data.shop_prefix
    })
  }, []);

  return (
    <Layout>
      <Header className="header">
        <div className="header-left">
          <div className="logo">
            <img onClick={getIndex} src={Logo} alt=''></img>
          </div>
        </div>
        {
          sessionStorage.getItem("sms_num")>=20 ? (
            <div> </div> ): (<div className='header-notice'>
            The balance of SMS is less than 20! In order to avoid your activity being suspended, please buy a SMS package immediately ---&gt; <a onClick={Buy_SMS}>Purchase SMS package</a>
            </div>)
        }
        
        <div className="header-right">
          <div className="head-portrait">
            <img src={People} alt=''></img>
          </div>
          <Dropdown className='head-select' overlay={menu}>
            <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              {userName}
            </span>
          </Dropdown>
        </div>
      </Header>
      <Layout>
        <Sider
          width={172} className="site-sider-background">
          <Menu
            className='menu-background'
            defaultSelectedKeys={keyWorls}
            mode="inline"
            onClick={handleClick}
          >
            {
              routers.map(
                npx => {
                  return (
                    <Menu.Item
                      key={npx.path}
                      icon={npx.icon}
                    >
                      {npx.title}
                    </Menu.Item>
                  )
                }
              )
            }
          </Menu>
          <div className='terms'>
            <p></p>
            <div onClick={pushTerms} className='terms-text'>Terms</div>
          </div>
        </Sider>
        <Layout>
          <Content
            className="site-layout-background"
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280
            }}
          >
            <Switch>
              <Route exact path="/" component={Index} />
              <Route path="/Index" component={Index} />
              <Route exact path="/Cut" component={Cut} />
              <Route path="/Cut/Details" component={ActivityDetails} />
              <Route path="/Cut/Shop" component={Shop} />
              <Route path="/Cut/Create" component={Create} />
              <Route path="/Cut/Edit" component={Edit} />
              <Route path="/Subscribe" component={Subscribe} />
              <Route path="/Theme" component={Theme} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}
