import './theme.scss'
import React, { useState } from 'react'

import "antd/dist/antd.css";
import computerImg from '@/assets/images/WechatIMG42.png'
import phoneImg1 from '@/assets/images/WechatIMG1.png'
import phoneImg2 from '@/assets/images/WechatIMG2.png'
import cutImg from '@/assets/images/phone_test.png'

export default function Theme() {

  var userName = sessionStorage.getItem("userName")

  let [choose, setChoose] = useState('computer')
  // let endcode = '{% endform %}'

  // let textWorld = `{% if product %}
  // <div id="price-ninja"></div>
  // <script type="application/javascript" src="https://thepriceninja.com/static/price-ninja.js"></script>
  // <script type="application/javascript">loadPriceNinja("{{ shop.domain }}", "{{ product.id }}")</script>
  // {% endif %}`

  const onChangeComputer = () => {
    setChoose('computer')
  }

  const onChangePhone = () => {
    setChoose('phone')
  }

  // 点击按钮实现复制功能
  // const copyMmeessage = () => {
  //   const activeCopy = document.querySelector("textarea")
  //   activeCopy.select()
  //   const tag = document.execCommand("Copy")
  // }

  const shopUrl = `https://${userName}.myshopify.com/admin/themes`

  return (
    <div className="theme">
      <div className='theme-top'>
        appearance
      </div>
      <div className='theme-main'>
        <div className='theme-left'>
          <p>Installation steps：</p>
          <p className='textmessage'>Go to your <a href={shopUrl}target="_blank">Shopify admin panel</a> &gt; Themes &gt; Customize &gt; Search for products and jump to &gt; Product information &gt; Add block &gt; Price Ninja &gt; Then move the buutton to the position you want </p>
          <p>
          If there is no display button, please click Campaign first, and then click Create campaign.
          </p>
          {/* <p>
            <a href='https://oblazo.s3.us-west-1.amazonaws.com/price_ninja/tutorial1.mp4' target='_blank'>Video Tutorial</a>
          </p>
          <div className='theme-input-mian'>
            <textarea
              id='theme-input'
              className='theme-input'
              value={textWorld}
              readOnly
            >
              {textWorld}
            </textarea>
            <div className='copy-btn'>
              <div className='btn' onClick={copyMmeessage}>copy</div>
            </div>
          </div> */}
          <p>
          If you don't know how to operate, please click the <a href='https://oblazo.s3.us-west-1.amazonaws.com/price_ninja/tutorial2.mp4' target='_blank'>link</a> to watch the video.
          </p>
          <p className='theme-change'>Please refresh the page after installation</p>
        </div>
        <div className='theme-middle'>

        </div>
        <div className='theme-right'>
          <div className='theme-right-tabbar'>
            <div className='topbar'>
              {
                choose === "computer" ? (<div><span className='click'>Pc</span>
                  <span onClick={onChangePhone} className='noclick'>Mobile</span></div>) : (<div><span onClick={onChangeComputer} className='noclick'>Pc</span>
                    <span className='click'>Mobile</span></div>)
              }
            </div>
            {
              choose === "computer" ? (<div className='theme-img-computer'>
                <img src={computerImg} alt='' />
              </div>) : (<div className='theme-img-phone'>
                <div className='tt'><img src={phoneImg1} alt='' /></div>
                <div className='tt'><img src={phoneImg2} alt='' /></div>
              </div>)
            }
            <div className='theme-title'>Bargain activity page</div>
            <div className='theme-active'>
              <img src={cutImg} alt='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}