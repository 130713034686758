import {get,postInstance,postQuery} from './until'

// 登录获取url
export const user_login = (params) => {
    return get('https://thepriceninja.com/api/shopify/login/',params);
}

// 获取用户名
export const user_name = (params) => {
    return get('https://thepriceninja.com/api/merchant/',params);
}

// 获取可用商品
export const available_goods = (params) => {
    return get('https://thepriceninja.com/api/products/',params);
}

// 创建砍价活动
export const create_activities = (query) => {
    return postInstance('https://thepriceninja.com/api/activities/',query);
}

// 查询活动
export const query_activities = (params) => {
    return get('https://thepriceninja.com/api/activities/',params);
}

// 查询砍价数据
export const bargain_data = (params) => {
    return get('https://thepriceninja.com/api/bargain-data/',params);
}

// 开始结束取消活动
export const stop_activities = (data) => {
    return postQuery(`https://thepriceninja.com/api/activity/${data.mode}/${data.activity_id}/`);
}

// 订阅
export const shop_subscribe = (params) => {
    return get('https://thepriceninja.com/api/subscribe/',params);
}

// 订阅之后返回id
export const shop_subscribed = (params) => {
    return get('https://thepriceninja.com/api/subscribed/',params);
}

// 购买短信包之后返回id
export const once_charge = (params) => {
    return get('https://thepriceninja.com/api/once-charge-success/',params);
}

// 获取是否为vip用户
export const shop_status = (params) => {
    return get('https://thepriceninja.com/api/status/',params);
}

// 获取短信包数据
export const sms_package = (params) => {
    return get('https://thepriceninja.com/api/once-charges/',params);
}

// 购买短信包
export const buy_sms_package = (params) => {
    return get('https://thepriceninja.com/api/once-charge/',params);
}

// 查询砍价订单
export const cut_bargains = (params) => {
    return get('https://thepriceninja.com/api/bargains/',params);
}

// 首页折线图
export const bargain_statistics = (params) => {
    return get('https://thepriceninja.com/api/bargain-statistics/',params);
}

// 获取用户名
export const users_name = (params) => {
    return get('https://thepriceninja.com/api/bargain-detail/',params);
}

// 点击砍价按钮
export const cut_record = (query) => {
    return postInstance('https://thepriceninja.com/api/record/',query);
}

// 登录发送验证码
export const cut_login = (query) => {
    return postInstance('https://thepriceninja.com/api/captcha/',query);
}