import service from './index'

export function get(url,params) {
    return service({
        method:'GET',
        url:url,
        data:{},
        params:params
    })
}

export function postInstance(url,query) {
    return service({
        method:'POST',
        url:url,
        data:query,
        // 一个字段可以传多个值
        traditional:true,
    })
}

export function postQuery(url,params) {
    return service({
        method:'POST',
        url:url,
        params:params
    })
}