import './bargain.scss'
import shopIcon from '@/assets/images/shop_white.png'
import userIcon from '@/assets/images/aboutus_white.png'
import ninjaImg from '@/assets/images/ninja_bg.png'
import handImg from '@/assets/images/hand_bg.png'

import One from '@/assets/images/one.png'
import Two from '@/assets/images/two.png'
import Three from '@/assets/images/three.png'

import React, { useState, useEffect } from 'react'
import { FatherContext } from '@/FatherContext'

import Cutting from '@/component/Cutting/index'
import Shareing from '@/component/Shareing/index'
import Ranking from '@/component/Ranking/index'
import BargainingRules from '@/component/BargainingRules'

import { users_name } from '@/request/api'

import { getUrlToken } from "@/getUrlCode"

import moment from 'moment-timezone'


export default function Terms(props) {

    const urlCode = getUrlToken("code", props.location.search)
    sessionStorage.setItem("code", urlCode);

    let [shopName, setShopName] = useState('');
    let [cutState, setCutState] = useState('cutting')
    let [shareflag, setShareflag] = useState(true)
    // 进度条数据
    let [precent, setPrecent] = useState(0)
    // 砍价排名数据
    let [ranking, setRanking] = useState([])
    // 砍价剩余时间
    let [hour, setHour] = useState('00')
    let [minute, setMinute] = useState('00')
    let [second, setSecond] = useState('00')
    // 砍价总价
    let [priceTotal, setPriceTotal] = useState('0')
    let [priceCut, setPriceCut] = useState('0')
    // 砍价按钮是否可以砍价
    let [flag, setFlag] = useState(false)
    // 砍价成功的url
    let [buyNow, setBuyNow] = useState('')
    // 砍价失败产品url
    let [productUrl, setProductUrl] = useState('')
    // 砍价规则弹窗
    let [rulesWindow, setRulesWindow] = useState('none')

    var zone = new Date().getTimezoneOffset() * 60 * 1000

    useEffect(() => {
        userMessage()
    }, [])

    const handleBack = () => {
        props.history.go(-1)
    }

    const titleOnclick = () => {
        setRulesWindow('block')
    }

    const userMessage = () => {
        let params = {
            code: sessionStorage.getItem("code")
        }
        users_name(params).then((res) => {
            console.log(res);
            // 处理返回的时间数据
            let endTime = moment(res.data.data.end_time).valueOf()

            // 总共要砍价的数据
            const bargain_total = res.data.data.bargain_total / 100

            // 截取前三的排名
            let test = res.data.data.records

            // 砍价进度条数据
            let pre = (res.data.data.bargain_total - res.data.data.bargain_rest) / res.data.data.bargain_total * 100

            if (pre >= 100) {
                setCutState('success')
            }

            test.forEach((item, index) => {
                item.price = returnFloat(item.bargain_price)
                if (index === 0) {
                    item.rank = One
                }
                if (index === 1) {
                    item.rank = Two
                }
                if (index === 2) {
                    item.rank = Three
                }
            })

            setPrecent(pre)
            setRanking(test)
            setShopName(res.data.data.shop_prefix)
            setPriceTotal(bargain_total)
            setPriceCut(() => {
                return (res.data.data.bargain_total - res.data.data.bargain_rest) / 100
            })
            setBuyNow(res.data.data.buy_now_url)
            setProductUrl(res.data.data.product_page_url)

            countFun(endTime,pre);
        })
    }

    // 处理时间，将时间转化为小时，分钟，秒并返回
    const countFun = (end,pre) => {
        // 使用零时区时间
        let now_time = Date.parse(new Date()) + zone * 1;
        var remaining = end - now_time;
        const timer = setInterval(() => {
            //防止出现负数
            if (remaining > 1000) {
                remaining -= 1000;
                let hour = Math.floor(remaining / 1000 / 3600);
                let minute = Math.floor((remaining / 1000 / 60) % 60);
                let second = Math.floor(remaining / 1000 % 60);

                setHour(() => {
                    return hour < 10 ? "0" + hour : hour
                })
                setMinute(() => {
                    return minute < 10 ? "0" + minute : minute
                })
                setSecond(() => {
                    return second < 10 ? "0" + second : second
                })
            } else {
                if(cutState !== 'success'){
                    if (pre < 100 && remaining <= 0) {
                        setCutState('fail')
                    }
                }
                clearInterval(timer);
            }
        }, 1000);
    }

    // 处理排名的数据，将数据保持为两位浮点数
    const returnFloat = (values) => {
        var value = Math.round(parseFloat(values) * 100) / 10000;
        var xsd = value.toString().split(".");
        if (xsd.length === 1) {
            value = value.toString() + ".00";
            return value;
        }
        if (xsd.length > 1) {
            if (xsd[1].length < 2) {
                value = value.toString() + "0";
            }
            return value;
        }
    }

    return (
        <>
            <FatherContext.Provider
                value={{
                    rulesWindow:rulesWindow
                }}
            >
                <BargainingRules
                    setRulesWindow={setRulesWindow}
                ></BargainingRules>
            </FatherContext.Provider>
            <div className='Share'>
                <div className='Share-header'>
                    <span>
                        <i onClick={handleBack} className="icon-jiantou_xianxing_zuo iconfont"></i>
                    </span>
                    <p>
                        Price Ninja | {shopName}
                    </p>
                </div>
                <div className='Share-main'>
                    <div className='main-top'>
                        <p className='main-top-left'>
                            {/* <span className='shop-icon'> */}
                                <img className='shop-icon-img' src={shopIcon} alt='' />
                            {/* </span> */}
                            <span className='shop-name'>
                                {shopName}
                            </span>
                        </p>
                        <p className='main-top-right'>
                            <span onClick={titleOnclick} className='right-icon'>
                                <img className='user-icon-img' src={userIcon} alt='' />
                            </span>
                        </p>
                    </div>
                    <div className='main-body'>
                        <div className='ninja-img'>
                            <div className='ninja-div'>
                                <img className='ninja-img-img' src={ninjaImg} alt='' />
                                <img className='cut-hand' src={handImg} alt='' />
                            </div>
                        </div>

                        <FatherContext.Provider
                            value={{
                                cutState: cutState,
                                shareflag: shareflag,
                                precent: precent,
                                ranking: ranking,
                                hour: hour,
                                minute: minute,
                                second: second,
                                priceTotal: priceTotal,
                                priceCut: priceCut,
                                flag: flag,
                                buyNow: buyNow,
                                productUrl: productUrl
                            }}
                        >
                            <Cutting></Cutting>
                            <Shareing></Shareing>
                            <Ranking></Ranking>
                        </FatherContext.Provider>
                    </div>
                </div>
            </div>
        </>
    )
}
