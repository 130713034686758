import './index.scss'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Input, Table, message } from 'antd';

import { available_goods } from '@/request/api'

import searchImg from '@/assets/images/home_search@2x.png'


const columns = [
  {
    title: '',
    dataIndex: 'image',
    key: 'name',
    render: image => <img src={image} alt='' />,
    width: 60,
  },
  {
    title: 'product',
    dataIndex: 'name',
  },
  {
    title: 'type',
    dataIndex: 'category',
  },
  {
    title: 'stock',
    dataIndex: 'inventory',
  },
];


class Apps extends React.Component {
  state = {
    selectedRowKeys: [], // Check here to configure the default column
    availableData: '',
    navBarList: [],
  };

  componentDidMount() {
    this.props.onRef && this.props.onRef(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ availableData: nextProps.tableList })
  }

  // 传递数据给父组件
  toParent = () => {
    this.props.parent(this, this.state.navBarList)
  }

  componentWillReceiveProps(nextProps) {
    nextProps.onRef && nextProps.onRef(this);
  }

  start = () => {
    return this.state.selectedRowKeys
  };

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
    this.state.selectedRowKeys = selectedRowKeys
  };

  render() {
    const { selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      selections: [
        Table.SELECTION_ALL,
        Table.SELECTION_INVERT,
        Table.SELECTION_NONE,
        {
          key: 'odd',
          text: 'Select Odd Row',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
        {
          key: 'even',
          text: 'Select Even Row',
          onSelect: changableRowKeys => {
            let newSelectedRowKeys = [];
            newSelectedRowKeys = changableRowKeys.filter((key, index) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            });
            this.setState({ selectedRowKeys: newSelectedRowKeys });
          },
        },
      ],
    };
    return (
      <div>
        <div style={{ marginBottom: 16 }}>
        </div>
        <Table rowSelection={rowSelection} columns={columns} dataSource={this.state.availableData} />
      </div>
    );
  }
}

// 父组件
const Create = (props) => {

  let topname = '> Select products';
  let editItem = '> Edit the campaign';

  let [tablebar, setClickbar] = useState([]);
  let [choiceCategory, setChoiceCategory] = useState('')
  let [tableList, setTableList] = useState('')
  let [title, setTitle] = useState('')

  const onSearch = e => {
    if(e.keyCode === 13) {
      setTitle(e.target.value)
    }
    productSelecttitle()
  }

  let ChildRef = null;

  const history = useHistory();
  // 点击下一步跳转到编辑页面
  // 调用子组件的方法获取选中的数据
  const handleClick = () => {
    // 选中的数据
    let selectData = ChildRef.start();
    let data = []
    if( selectData.length === 0){
      message.error("Please select a product")
    }else{
      selectData.map((item, index) => {
        let obj = {}
        obj.product_id = item[0]
        obj.handle = item[1]
        obj.title = item[2]
        obj.image = item[3]
        obj.price = item[4]
        data.push(obj)
      })
      // 将选中的商品存入本地
      localStorage.setItem("products", JSON.stringify(data));
      // 路由传递选中的数据
      history.push({ pathname: '/Cut/Edit', query: { data: data } })
    }

    
  }

  // 调用子组件的方法获取选中的数据
  // const handleClick =() => {
  //     ChildRef.start();
  // }

  const getChildrenMsg = (result, msg) => {
    // result就是子组件那bind的第一个参数this，msg是第二个参数
    // setClickbar(() => {
    //   return msg
    // })
  }

  // 获取可选产品数据
  const product = () => {
    let params = {
      page: 1,
      category: '',
      title: ''
    }
    available_goods(params).then((res) => {
      res.data.data.categories.unshift('All')
      const dataNav = [];
      res.data.data.categories.forEach((item) => {
        dataNav.push({
          name: item,
          activeColor: false
        })
      })
      dataNav[0].activeColor = true
      setClickbar(() => {
        return dataNav
      })
    }).catch((err) => {
    })
  }


  // 获取根据条件查询的商品
  const productSelect = () => {
    let params = {
      page: 1,
      category: choiceCategory,
      title: ''
    }
    available_goods(params).then((res) => {
      const data = [];
      res.data.data.products.forEach((item) => {
        data.push({
          key: [item.id, item.handle, item.title, item.image, item.price],
          image: item.image,
          name: item.title,
          category: item.category,
          inventory: item.inventory,
        })
      })
      // 选中商品的数据
      setTableList(() => {
        return data
      })
    }).catch((err) => {
      
    })
  }

  // 获取根据标题查询的商品
  const productSelecttitle = () => {
    let params = {
      page: 1,
      category: '',
      title: title
    }
    available_goods(params).then((res) => {
      const data = [];
      res.data.data.products.forEach((item) => {
        data.push({
          key: [item.id, item.handle, item.title, item.image, item.price],
          image: item.image,
          name: item.title,
          category: item.category,
          inventory: item.inventory,
        })
      })
      setTableList(() => {
        return data
      })
    }).catch((err) => {
      
    })
  }

  useEffect(() => {
    product()
  }, [])

  useEffect(() => {
    productSelect()
  }, [choiceCategory])

  useEffect(() => {
    productSelecttitle()
  }, [title])

  return (
    <div>
      <div className="create-topbar">
        <div className="create-left">
          <p className="create-clickbar">Campaign</p>
          <span className='topname'>{topname}&nbsp;</span>
          
          <span className='deitItem'>{editItem}</span>
        </div>
        <div onClick={handleClick} className="create-btn">
          Next step
        </div>
      </div>
      <div className='create-main'>
        <div className='table-bar'>
          {
            tablebar.map((item, index) => {
              return (
                <span
                  className={item.activeColor ? 'point' : 'nopoint'}
                  style={{ color: item.activeColor ? '#000' : '#BBB', }}
                  onClick={e => {
                    let navList = [...tablebar]
                    navList.map((item, idx) => {
                      if (idx === index) {
                        navList[idx].activeColor = true
                        // 选中的标题
                        setChoiceCategory(() => {
                          if (item.name === 'All') {
                            return ''
                          } else {
                            return item.name
                          }
                        })
                      } else {
                        navList[idx].activeColor = false
                      }
                    })
                  }}
                  key={index}
                >{item.name}</span>
              )
            })
          }
        </div>
        <div className='create-search'>
          <div className="search-input">
            <img src={searchImg} alt=""></img>
            <Input className='input-main' placeholder="Search product ..." onKeyDown={e => onSearch(e)} />
          </div>
        </div>
        <div>
          <Apps
            parent={getChildrenMsg}
            onRef={node => ChildRef = node}
            tableList={tableList}
          />
        </div>
      </div>
    </div>
  )
}

export default Create
