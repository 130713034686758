import './index.scss'
import React, { useState, useContext } from 'react'
import { FatherContext } from '@/FatherContext'

import Close from '@/assets/images/popup_close.png'
import { cut_login } from '@/request/api'

import { Select } from 'antd';
const { Option } = Select;

export default function Login(props) {

    let [btnClass, setBtnClass] = useState(false)
    let [likeMessage, setLikeMessage] = useState(true)
    let [likeTime, setLikeTime] = useState(60)

    let optionData=[
        // 美国
        { name: '1', value: '1' },
        // 南非
        { name: '27', value: '27' },
        // 奥地利
        { name: '43', value: '43' },
        // 澳大利亚
        { name: '61', value: '61' },
        // 比利时
        { name: '32', value: '32' },
        // 瑞士
        { name: '41', value: '41' },
        // 智利
        { name: '56', value: '56' },
        // 德国
        { name: '49', value: '49' },
        // 丹麦
        { name: '45', value: '45' },
        // 芬兰
        { name: '358', value: '358' },
        // 法国
        { name: '33', value: '33' },
        // 英国
        { name: '44', value: '44' },
        // 中国香港
        { name: '852', value: '852' },
        // 冰岛
        { name: '354', value: '354' },
        // 印度
        { name: '91', value: '91' },
        // 意大利
        { name: '39', value: '39' },
        // 墨西哥
        { name: '52', value: '52' },
        // 马来西亚
        { name: '60', value: '60' },
        // 荷兰
        { name: '31', value: '31' },
        // 挪威
        { name: '47', value: '47' },
        // 新西兰
        { name: '64', value: '64' },
        // 菲律宾
        { name: '63', value: '63' },
        // 罗马尼亚
        { name: '40', value: '40' },
        // 瑞典
        { name: '46', value: '46' },
        // 新加坡
        { name: '65', value: '65' },
    ]

    let [Areacode, setAreacode] = useState('+1')
    let [phone, setPhone] = useState('')
    let [telephone, setTelephone] = useState('')
    let [Verification, setVerification] = useState('')

    let loginWindow = useContext(FatherContext).loginWindow

    // 点击关闭登录框
    const hide = () => {
        props.setLoginWindow('none')
    }

    // 选择的区号
    const handleChange = (e) => {
        setAreacode(() => {
            return "+" + e
        })
        setTelephone(() => {
            return "+" + e + phone
        })
    }

    // 输入的电话号码
    const inputChangePhone = (e) => {
        setPhone(e.target.value)
        setTelephone(() => {
            return Areacode + e.target.value
        })
    }

    // 点击发送短信
    const onSend = () => {
        setLikeMessage(false)
        const params = {
            telephone: telephone,
            code: sessionStorage.getItem("code")
        }
        cut_login(params).then((res) => {
            console.log(res);
        })

        let times = likeTime;
        let timer = setInterval(function () {
            if (times < 1) {
                setLikeMessage(true)
                clearInterval(timer);
            } else {
                times -= 1;
                setLikeTime(times);
            }
        }, 1000);
    }



    const inputChangeVerification = (e) => {
        // 短信验证码的值
        console.log(e.target.value);
        setBtnClass(true)
        setVerification(e.target.value)
    }

    const getInputValue = () => {
        let params = {
            telephone: telephone,
            captcha: Verification
        }
        props.setTel(params)
        props.setLoginWindow('none')
        props.setLogin(true)
        props.setUserName(telephone)
    }

    return (
        <div className="mask" style={{ display: loginWindow }}>
            <div className='content'>
                <div className='close'>
                    <span className="delspan" onClick={hide}>
                        <img className="delimg" src={Close} alt='' />
                    </span>
                </div>
                <div className='login-title'>
                    Mobile number login
                </div>
                <div className='loginForm'>
                    <div className="login-top">
                        <div className="select-ul">
                        </div>
                        <div className="phone-header">
                            <Select popupClassName="select-login" suffixIcon='' className="phone-select" value={Areacode} style={{ width: "3.75rem" }} onChange={handleChange}>
                                {
                                    optionData.map((item, index) => {
                                        return (
                                            <Option key={index} value={item.value}>{item.name}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        <input className='loginPhone'
                            onChange={(e) => { inputChangePhone(e) }}
                        />
                    </div>
                    <div className='Verification'
                        onChange={(e) => { inputChangeVerification(e) }}
                    >
                        <input className='loginVerification' placeholder='verification' />
                        {
                            likeMessage === true ? (
                                <span
                                    className='loginSend'
                                    onClick={onSend}
                                >
                                    SEND
                                </span>
                            ) : (
                                <span
                                    className='loginResend'
                                >
                                    resend({likeTime}s)
                                </span>
                            )
                        }

                    </div>
                    <div className='loginTips'>
                        *The phone number is used to receive
                        your store offers
                    </div>
                    {
                        btnClass === false ? (
                            <div className='loginBtn'
                            >
                                Sign in
                            </div>
                        ) : (
                            <div className='loginBtn2'
                                onClick={getInputValue}
                            >
                                Sign in
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}